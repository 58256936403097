<template>
<div>
	<article class="quiz">
		<h1 class="quiz__header">
			<img src="@/assets/img/heatstroke_quiz2022/quiz_title.png" alt="5日でマスター! 熱中症＆夏バテ対策クイズ">
		</h1>

		<section class="quiz__body">
			<div class="quiz__body--text">
				<p>1日1問のクイズに5回答えると、<br>頭痛ーるキャラクターのスタンプをプレゼント！<br><span>掲載期間：2022年8月31日まで</span></p>
			</div>

			<div v-if="answerFlag" class="quiz__body--content">
				<p>本日の問題</p>
				<span v-html="quizProblem" />
			</div>

			<div class="quiz__body--description">
				<i class="sprite_quiz balloon" />
				<p v-html="balloonText" />
			</div>

			<div v-if="!answerFlag && levelAchievement === 6" class="quiz__body--stamp">
				<ul>
					<li><img src="~@/assets/img/stamp/summer202207_stamp01.png" alt="暑すぎぃ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp03.png" alt="夏に負けるニャ"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp04.png" alt="どんとこい夏"></li>
					<li><img src="~@/assets/img/stamp/summer202207_stamp06.png" alt="突然の雨"></li>
				</ul>
				<a href="javascript:void(0)" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'summer', _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症＆夏バテ対策クイズリンク21" data-gtm-event-act-label="trackingQuizuProblemBtn21">
					プレゼントをもらう
				</a>
			</div>

			<div v-if="answerFlag" class="quiz__body--answer">
				<a href="javascript:void(0)" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=heatstrokeQuizAnswer`, _answer: 0 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症＆夏バテ対策クイズリンク19" data-gtm-event-act-label="trackingQuizuProblemBtn19">
					<i class="sprite_quiz btn01" />
				</a>
				<a href="javascript:void(0)" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=heatstrokeQuizAnswer`, _answer: 1 } })" class="gtm-link_click_count01" data-gtm-event-cat-label="熱中症＆夏バテ対策クイズリンク20" data-gtm-event-act-label="trackingQuizuProblemBtn20">
					<i class="sprite_quiz btn02" />
				</a>
			</div>

			<div v-if="!answerFlag" class="quiz__body--levelachievement">
				<i class="sprite_level_achievement" :class="`level${levelAchievement}`" />
			</div>

			<p class="quiz__body--link" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'heatstroke2022', _backPage: 'top' } })">熱中症＆夏バテ特集にもどる</p>
		</section>

		<aside class="quiz__back-button--wrapper">
			<p class="quiz__back-button" @click="$router.push({ name: 'Cpsite', query: { id: `${sugotokuContentsId}` } })">TOPにもどる</p>
		</aside>
	</article>
</div>
</template>

<script>

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA } from '@/store/modules/common/mutation-types'

export default {
	name: 'HeatstrokeQuizProblem',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			quizProblem: '',
			answerFlag: false,
			balloonText: '',
			levelAchievement: 1
		}
	},
	mounted () {
		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData', this.userData)
				this.setQuiz()
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.setQuiz()
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData'])
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA]),

		// クイズの出し分け
		setQuiz () {
			const offset = '+09:00'
			const accessDate = moment(new Date()).utcOffset(offset)
			const webStorage = this.userData.teigaku.web_storage.data ? JSON.parse(this.userData.teigaku.web_storage.data) : null
			if (!webStorage || !webStorage.hasOwnProperty('q1') || (webStorage.q_answer_date !== accessDate.format('YYYYMMDD') && webStorage.q5 === 0)) {
				// NOTE: 解答日がアクセス日と同じでない場合かつ、全てのクイズを答えていない場合は問題を表示
				this.balloonText = '〇か×、正解だと思うほうをタップするのじゃ！'
				this.answerFlag = true
				this.quizProblem = !webStorage || !webStorage.hasOwnProperty('q1') ? '熱中症の予防のためには、ピタっとした黒色系の服を着ると良い。<br>〇か×か？'
					: webStorage.q2 === 0 ? '熱中症の危険度の高い日の水分補給には、塩分が含まれた飲料が適している。<br>〇か×か？'
						: webStorage.q3 === 0 ? '夏バテの予防のためには、湯船につからず軽くシャワーですませたほうが良い。<br>〇か×か？'
							: webStorage.q4 === 0 ? '風が弱い日や湿度が高い日は、熱中症の危険度は低い。<br>〇か×か？'
								: '夏バテの予防のためには、冷たい食べ物や飲み物をとりすぎないほうが良い。<br>〇か×か？'
			} else {
				// NOTE: 解答日がアクセス日と同じまたは、全てのクイズが解答済みの場合は解答済みを表示
				this.balloonText = webStorage.q5 === 1 ? 'クイズは全部回答済みじゃぞ。<br>スタンプをゲットするのじゃ！' : '今日のクイズは回答済みじゃぞ。<br>また明日チャレンジじゃ！'
				this.answerFlag = false
				this.levelAchievement = webStorage.q2 === 0 ? 2
					: webStorage.q3 === 0 ? 3
						: webStorage.q4 === 0 ? 4
							: webStorage.q5 === 0 ? 5
								: 6
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このコンポーネント限定の変数（デザインシステム外のため他での利用禁止）
$background-quiz: #99DAF1;
$period-title-color: #FF4D5F;
$link-text-color: #0859E4;
$background-color-blue: #0071DB;
$background-color-lightgray: #F5F5F5;
$back-button-bg-orange: #FE8F11;
$back-button-shadow-bg-orange: #C9730D;
$sprite-scale-size: .895;

.quiz {
	background: $background-color-lightgray;
	padding-bottom: $spacing-30;
	&__header {
		padding-top: $spacing-10;
		margin: 0;
		box-sizing: border-box;
		img {
			width: 100%;
			vertical-align: bottom;
		}
	}

	&__body {
		padding-bottom: 58px;
		background: $background-quiz;
		position: relative;
		&--text {
			margin: 0 auto;
			font-size: $font-size-14;
			text-align: center;
			font-family: $lp-family-primary;
			p {
				margin: 0;
				span {
					font-family: $lp-family-tertiary;
					color: $period-title-color;
				}
			}
		}

		&--content {
			width: 335px;
			margin: $spacing-14 auto 0;
			p {
				width: 100%;
				height: 30px;
				margin: 0;
				display: block;
				line-height: 1.875;
				border-radius: 10px 10px 0px 0px;
				background: $background-color-blue;
				font-family: $lp-family-tertiary;
				font-size: $font-size-16;
				text-align: center;
				color: $text-seconday;
			}
			span {
				width: 100%;
				padding: $spacing-10 $spacing-14 $spacing-8 $spacing-14;
				text-align: left;
				font-size: $font-size-16;
				font-family: $lp-family-primary;
				color: $text-primary;
				display: block;
				box-sizing: border-box;
				background: $background-primary;
				border: 2px dashed $background-color-blue;
				border-top: none;
				border-radius: 0px 0px 10px 10px;
			}
		}

		&--description {
			width: 335px;
			margin: $spacing-14 auto;
			position: relative;
			p {
				text-align: left;
				margin: 0;
				position: absolute;
				right: 15px;
				top: 19px;
				width: 220px;
				font-size: $font-size-14;
				font-family: $lp-family-primary;
				color: $text-primary;
			}
		}

		&--stamp {
			width: 335px;
			margin: 0 auto $spacing-20;
			ul {
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				li {
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 78px;
					height: 78px;
					background-color: $background-primary;
					overflow: hidden;
					img {
						display: block;
						width: 100%;
						pointer-events: none;
					}
				}
			}

			a {
				margin: 0 auto;
				padding: $spacing-10 0;
				border-radius: 6px;
				display: block;
				width: 295px;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-style: normal;
				font-size: $font-size-18;
				text-align: center;
				text-decoration: none;
				background-color: $back-button-bg-orange;
				box-shadow: 0 3px 0 0 $back-button-shadow-bg-orange;
			}

		}

		&--answer {
			width: 335px;
			margin: 0 auto $spacing-20;
			display: flex;
			align-items: center;
			justify-content: space-around;
			i {
				box-sizing: border-box;
			}
		}

		&--levelachievement {
			width: 335px;
			margin: 0 auto $spacing-20;
		}

		&--link {
			margin: 0;
			text-align: center;
			color: $link-text-color;
			font-size: $font-size-16;
			font-family: $lp-family-tertiary;
			text-decoration: underline;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			background-image: url(~@/assets/img/heatstroke_quiz2022/nami_bg.svg),  url(~@/assets/img/heatstroke_quiz2022/nami_bg.svg);
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 28px;
		}
	}

	&__back-button {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin:0 auto;
		padding: $spacing-10 $spacing-10 $spacing-8;
		text-align: center;
		text-decoration: none;

		&--wrapper {
			padding-top: $spacing-20;
		}
	}

	@media (max-width: (374px)) {
		.quiz__body {
			&--text {
				width: 300px;
				font-size: $font-size-12;
			}
			&--content {
				width: 300px;
				p {
					height: 20px;
					font-size: $font-size-12;
				}
				span {
					font-size: $font-size-12;
				}
			}
			&--description {
				width: 300px;
				height: 74px;
				i {
					transform: scale($sprite-scale-size);
					transform-origin: 0 0;
				}
				p {
					right: 15px;
					top: 18px;
					width: 194px;
					font-size: $font-size-12;
				}
			}
			&--levelachievement {
				width: 300px;
				height: 88px;
				i {
					transform: scale($sprite-scale-size);
					transform-origin: 0 0;
				}
			}
			&--answer {
				width: 300px;
				a > i {
					width: 136px;
					height: 60px;
					background-size: 300px 232px;
					background-position: -8px -83px;
				}
				a:nth-child(2) > i {
					background-position: -8px -8px;
				}
			}
			&--stamp {
				width: 300px;
				li {
					width: 68px;
					height: 68px;
				}
				a {
					width: 255px;
					font-size: $font-size-16;
				}
			}
		}
	}
}

.sprite_quiz {
	background-image: url(~@/assets/img/heatstroke_quiz2022/sprite_quiz_popup.png);
	background-size: 355px 288px;
	background-repeat: no-repeat;
	display: block;

	&.balloon {
		width: 335px;
		height: 82px;
		background-position: -10px -196px;
	}

	&.btn01 {
		width: 160px;
		height: 73px;
		background-position: -10px -103px;
	}

	&.btn02 {
		width: 160px;
		height: 73px;
		background-position: -10px -10px;
	}
}

.sprite_level_achievement {
	background-image: url(~@/assets/img/heatstroke_quiz2022/sprite_level_achievement.png);
	background-size: 345px 690px;
	background-repeat: no-repeat;
	display: block;

	&.level1 {
		width: 335px;
		height: 105px;
		background-position: -5px -5px;
	}

	&.level2 {
		width: 335px;
		height: 105px;
		background-position: -5px -120px;
	}

	&.level3 {
		width: 335px;
		height: 105px;
		background-position: -5px -235px;
	}

	&.level4 {
		width: 335px;
		height: 105px;
		background-position: -5px -350px;
	}

	&.level5 {
		width: 335px;
		height: 105px;
		background-position: -5px -465px;
	}

	&.level6 {
		width: 335px;
		height: 105px;
		background-position: -5px -580px;
	}
}
</style>
